<template>
  <div id="help-category">
    <v-flex xs12>
      <v-layout wrap class="help">
        <v-flex xs11 sm11 md11 offset-sm1 offset-md1 v-if="!isMobile">
          <div class="text"> {{ $t('help') }} : 
            <span v-if="data"> {{ data.judul }} </span>
          </div>
          <v-btn icon color="black" @click="back()" class="ml-7 text-1">
            <v-icon small>mdi-arrow-left</v-icon> {{ $t('back') }}
          </v-btn>
        </v-flex>
        <v-flex xs10 sm10 md10 offset-xs1 offset-sm1 offset-md1 class="mt-9">
          <v-card
            elevation="0"
            max-width="100%"
            tile
            v-if="data"
          >
            <v-card-text class="text-3" v-if="isMobile">
              <img v-bind:src="data.icon" width="20px" alt="image">
              <span class="ml-3" v-html="data.judul"></span>
            </v-card-text>

            <v-card-text class="text-2">
              <div v-html="data.konten"></div>
            </v-card-text>
          </v-card>
         </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'HelpDetail',
    computed: {
      ...mapState([
        'isMobile'
      ])
    },
    data () {
      return {
        data: null
      }
    },
    methods: {
      getHelpCategory () {
        this.$store.commit('setIsLoading', true)
        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          category_code: this.$route.params.category,
          is_offline: true
        }

        axios.get('/api/v1/pages/bantuan/'+ this.$route.params.slug)
          .then(response => {
            this.data = response.data.data
            this.setMetaInfo(null, this.data.judul)
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      }
    },
    mounted () {
      if (this.$route.params.slug && this.$route.params.category) {
        this.getHelpCategory()
      }
      
      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setActivePage', this.$t('help'))
    }
  }
</script>

<style scoped>
  .text {
    font-size: 1.7rem;
    color: #000 !important;
    font-weight: 600;
  }

  .text-1 {
    margin-top: 1rem;
    font-size: 10px;
    color: #000 !important;
    text-align: justify;
    text-justify: inter-word;
  }

  .text-2 {
    margin-top: 1rem;
    font-size: 14px;;
    color: #000 !important;
    text-align: justify;
    text-justify: inter-word;
  }

  .text-3 {
    margin-top: 1rem;
    font-size: 18px;
    font-weight: bold;
    color: #000 !important;
    text-align: justify;
    text-justify: inter-word;
  }

  .help {
    color: #FFFFFF;
    position: relative;
    margin: auto;
    margin-top: 10%;
  }
</style>
